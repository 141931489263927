<template>
	<div>
		<van-cell-group style="display: flex;justify-content:space-between;margin-bottom: 10px;">
			<div style="width: 80%;">
				<van-cell title="订单编号" :value="info.order_no" />
				<van-cell title="创建时间" :value="dataF(info.createtime)" />
			</div>
			<div style="width: 20%; background-color: gray;text-align: center;line-height: 80px;font-size: 14px;color: white;"
			 v-show="info.status == -1">
				已取消
			</div>
			<div style="width: 20%; background-color: gray;text-align: center;line-height: 80px;font-size: 14px;color: white;"
			 v-show="info.status == 0">
				待支付
			</div>
			<div style="width: 20%; background-color: #ff9e03;text-align: center;line-height: 80px;font-size: 14px;color: white;"
			 v-show="info.group_status == 0 && info.status != -1 && info.status != 0 && info.status != 2">
				待成团
			</div>
			<div style="width: 20%; background-color: #409EFF;text-align: center;line-height: 80px;font-size: 14px;color: white;"
			 v-show="info.group_status == 1 && info.status != -1 && info.status != 0 && info.status != 2">
				已成团
			</div>
			<div style="width: 20%; background-color: #09bb07;text-align: center;line-height: 80px;font-size: 14px;color: white;"
			 v-show="info.status == 2">
				已核销
			</div>
		</van-cell-group>

		<van-cell-group style="margin-bottom: 10px;">
			<van-cell title="产品名称" :value="info.goods.good_name" />
			<van-cell title="单买价格" :value="'￥' + info.goods.alone_price" />
			<van-cell title="拼团价" :value="'￥' + info.goods.groupbuy_price" />
			<van-cell title="支付金额" :value="'￥' + info.final_price" />
			<van-cell title="购买类型" :value="info.is_groupbuy == 1 ? '拼团' : '单买'" />
			<van-cell title="购买数量" :value="info.amount" />
			<van-cell title="详细地址" :value="info.receiver_address" />
			<van-cell title="邀请拼团" @click="invite()" is-link v-show="info.group_status == 0 && info.status > 0 && info.is_groupbuy == 1" />
		</van-cell-group>

		<van-cell-group style="margin-bottom: 10px;">
			<van-cell title="收货人姓名" :value="info.receiver_name" />
			<van-cell title="收货人电话" :value="info.receiver_mobile" />
		</van-cell-group>

		<van-cell-group style="margin-bottom: 10px;" v-if="info.status > 0 && info.group_status == 1">
			<van-cell title="爆款核销码" :value="info.code" />
			<div style="width: 100px;margin: 0 auto;margin-top: 10px;margin-bottom: 10px;">
				<img style="width: 100%;" :src="info.qrcode" />
			</div>
		</van-cell-group>

		<div style="padding: 10px;" v-show="info.status == 0">
			<van-button type="info" block="" @click="pay()">立即支付</van-button>
		</div>

		<div class="bottom-tip">
			<div>温馨提示：</div>
			<div>1.如5分钟内未支付，该订单将自动关闭</div>
			<div>2.支付后，请于活动结束前到该品牌所属门店核销</div>
		</div>
	</div>
</template>

<script>
	import {
		Field,
		Row,
		Col,
		Cell,
		CellGroup,
		Picker,
		Popup,
		DatetimePicker,
		Uploader,
		SwitchCell,
		Checkbox,
		Button,
		Toast,
		Stepper,
		Slider
	} from "vant";
	import {
		groupbuy_user_orderdetail,
	} from '../../../../request/api.js'
	import wx from "@/request/utils/wechat";//微信JSSDK
	
	export default {
		components: {
			[Field.name]: Field,
			[Row.name]: Row,
			[Col.name]: Col,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Picker.name]: Picker,
			[Popup.name]: Popup,
			[DatetimePicker.name]: DatetimePicker,
			[Uploader.name]: Uploader,
			[SwitchCell.name]: SwitchCell,
			[Checkbox.name]: Checkbox,
			[Button.name]: Button,
			[Stepper.name]: Stepper,
			[Slider.name]: Slider
		},
		data() {
			return {
				order_id: 0,
				info: {},
				activity_id: ''
			}
		},
		mounted() {
			this.order_id = this.$route.query.id;
			this.activity_id = this.$route.query.activity_id
			this.initData();
		},
		methods: {
			initData() {
				groupbuy_user_orderdetail({
					order_id: this.order_id,
				}).then(res => {
					if (res.code == 1) {
						this.info = res.data;
						this.$set(this.info, 'goods', JSON.parse(this.info.goods_info))
						console.log(this.info)
					} else {
						this.$dialog({
							title: '提示',
							message: res.msg
						}).then(g => {
							this.$router.go(-1);
						});
					}
				}).catch(error => {
					this.$dialog({
						title: '提示',
						message: "访问出错"
					}).then(g => {
						this.$router.go(-1);
					});
				});
			},
			invite() {
				this.$router.push({
					path: '/activity/marketing/group/invite',
					query: {
						activity_id: this.activity_id,
						group_id: this.info.group_id
					}
				})
			},
			dataF: function(timestamp) {
				var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var Y = date.getFullYear() + '-';
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				var D = date.getDate() + ' ';
				var h = date.getHours() + ':';
				var m = date.getMinutes() + ':';
				var s = date.getSeconds();
				return Y + M + D + h + m + s;
			},
			pay() {
				this.$router.push({
					path: '/payment/method',
					query: {
						id: this.info.id,
						type: 'group'
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.bottom-tip {
		font-size: 12px;
		padding: 15px;
		line-height: 20px;
		letter-spacing: 1px;
		color: gray;
	}
</style>
